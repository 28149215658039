import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'

import { AuthService } from '@service/auth/auth.service'
import { CoreModule } from '@shared/core.module'
import { SharedModule } from '@shared/shared.module'

import { AppRoutingModule } from './app-routing.module'
import { AngularAppConfig } from './app.model'

import { SnackbarComponent } from '@component/shared/snackbar/snackbar.component'
import { CustomMatFormFieldDirective } from '@directive/material/input-override.directive'
import { AuthEffects } from '@effect/auth.effect'
import { DashboardPageEffect } from '@effect/dashboard-page.effect'
import { GlobalAppEffects } from '@effect/global-app.effect'
import { MapPageEffects } from '@effect/map.effect'
import { MessageEffects } from '@effect/message.effect'
import { ResponseGroupEffects } from '@effect/response-group.effect'
import { RouteEffects } from '@effect/route.effect'
import { SchoolAuthEffects } from '@effect/school.effect'
import { SignalrEffects } from '@effect/signalr.effect'
import { UserEffects } from '@effect/user.effect'
import { PilotUserSignUpFormModel } from '@model/user/pilot-user-sign-up.model'
import { DashboardPageComponentService } from '@page/dashboard/dashboard-page.component.service'
import { InstructionsPageComponentService } from '@page/instructions/instructions-page/instructions-page.component.service'
import { WelcomePageComponentService } from '@page/public/welcome-page/welcome-page.component.service'
import { RosterPageComponentService } from '@page/roster/roster-page.component.service'
import { AppComponentService } from '@service/app.component.service'
import { LocalStorageService } from '@service/browser/local-storage.service'
import { SessionStorageService } from '@service/browser/session-storage.service'
import { ViewportService } from '@service/browser/viewport.service'
import { VisibilityService } from '@service/browser/visibility.service'
import { LoggingService } from '@service/logging/logging.service'
import { UserMetaDataService } from '@service/logging/user-meta-data.service'
import { ApiService } from '@service/network/api.service'
import { SchoolApiService } from '@service/network/school.service'
import { OwlRiveService } from '@service/rive/rive.service'
import { AppComponent } from './app.component'
import { ModalComponent } from './component/shared/modal/modal.component'
import { PilotUserApiService } from '@service/network/pilot-user.service'
import { EventsPageComponentService } from '@page/events/events-page/events-page.component.service'

@NgModule({
  declarations: [
    AppComponent,
    SnackbarComponent,
    ModalComponent,
    //TEMP
    CustomMatFormFieldDirective
  ],
  imports: [
    //Managed Code
    CoreModule,
    SharedModule,
    //3rd Party Code
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(AngularAppConfig.reducers),
    // StoreModule.forRoot(AngularAppConfig.reducers, {
    //   runtimeChecks: {
    //     strictActionImmutability: environment.production ? false : true,
    //     strictStateImmutability: environment.production ? false : true
    //   }
    // }),
    // TODO Only include dev tools for the dev environment which means two build pipelines one for dev and one for prod where we don't include dev tools, not even log only as that takes compute that we need for real time data updates.
    StoreDevtoolsModule.instrument(AngularAppConfig.devToolsConfig),
    EffectsModule.forRoot([
      RouteEffects,
      SignalrEffects,
      GlobalAppEffects,
      AuthEffects,
      DashboardPageEffect,
      SchoolAuthEffects,
      MessageEffects,
      ResponseGroupEffects,
      MapPageEffects,
      UserEffects
    ]),
    StoreRouterConnectingModule.forRoot(AngularAppConfig.navigationConfig)
  ],
  providers: [
    AppComponentService,
    VisibilityService,
    OwlRiveService,
    ViewportService,
    ApiService,
    AngularAppConfig.appInitConfig,
    AuthService,
    SchoolApiService,
    DashboardPageComponentService,
    RosterPageComponentService,
    InstructionsPageComponentService,
    EventsPageComponentService,
    WelcomePageComponentService,
    PilotUserSignUpFormModel,
    PilotUserApiService,
    LocalStorageService,
    SessionStorageService,
    UserMetaDataService,
    LoggingService
  ],
  exports: [SharedModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
