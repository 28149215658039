import { mockUnknownLogicalId } from '@mock/school.mock'
import { MapApiEnum } from '@view/map/map-enums.view'
import { OwlMapPolygonColor } from '@view/map/map.view'
import {
  IMapUiControlSessionSettings,
  MapUiControlEnum
} from '@view/pages/dashboard-page/dashboard-page.view'

//View
export const notApplicableDisplayString = 'N/A'

//Text validation
/**
 * Alphanumeric regex that allows spaces
 */
export const alphanumericRegex = new RegExp('^[a-zA-Z0-9 ]*$')
// export const alphanumericRegex = new RegExp('/^[a-zA-Z0-9]*$/')
export const alphanumericValidationMessage = `Must be alphanumeric.`

//Shared Network Strings
export const secureUrlPrefix = 'https://'
export const nonSecureUrlPrefix = 'http://'
export const localHostText = 'localhost'

//UI Constants
export const MIN_UI_RELATED_STATE_THROTTLE_TIME = 1000
export const MIN_STATE_UPDATE_THROTTLE_TIME = 40
export const MIN_MAIN_UI_STATE_UPDATE_THROTTLE_TIME = 600
export const TOAST_LIFETIME = 5000
export const TOAST_LIFETIME_BUFFER = 300
export const getFullToastLifeTime = () => TOAST_LIFETIME + TOAST_LIFETIME_BUFFER * 2
export const DISABLE_BUTTON_TIMEOUT = 60000

//FORM VALIDATION
/** Allows for letters, spaces, dashes, apostrophe, and periods */
export const nameRegex = '^[a-zA-Z-\' .]*$'
// Global OWL Phone Number Rules
//  owl mobile app usage is only allowed in the united states
export const phoneCountryCode = '+1'
//  phone must include +1 and 10 digits
export const phoneRegex = '^\\+1[0-9]{10}$'
//  phone formatting mask
export const phoneMask = '(000) 000-0000'
//  +1 is a prefix in create/edit user form, so validation is for 10 digits
export const phoneMaxLength = 10
export const maxNameCharacters = 200

//Map Constants
export const mapDomSelector = 'map-div-id'
export const mainUiSelector = 'map-ui-element-bottom-right'
export const defaultMapApi = MapApiEnum.arcGis
export const defaultMapBoxZoom = 14
export const defaultOlZoom = 16
export const defaultArcGisZoom = 17
export const unitedStatesZoom = 4
export const tempHardCodedRotation = 0 // 90
// geomtery styles
// temp red so that it's clear what is the boundary
export const defaultDashboardBoundaryStyle: OwlMapPolygonColor = [242, 0, 0, 0.5]
export const defaultConfigMapBoundaryStyle: OwlMapPolygonColor = [173, 216, 230, 0.1]

//Alert Constants
export const DEFAULT_ALERT_PROXIMITY = 5280 //feet

//Default global chat room is driven by the school ids passed in user context, then signal r connection for chat room data is based on that context TODO Determine ability to filter or select school/district ids.
export const DEFAULT_CHATROOM_ID = mockUnknownLogicalId
export const DEFAULT_CHATROOM_DISPLAY_TEXT = 'Everyone'
export const DEFAULT_ENTIRE_SCHOOL_DISPLAY_TEXT = 'Entire School'
export const ENTIRE_SCHOOL_CHATROOM_ID = '00000000-0000-0000-0000-000000000001'
export const ENTIRE_SCHOOL_CHATROOM_DISPLAY_TEXT = 'Entire School'

//DrillMode
export const drillModeLabel = 'Drill mode'
export const drillModeEmergencyLabel = 'NOT A DRILL!'
export const drillModeActiveEmergencyLabel = 'THIS IS A DRILL'

//Image Names
export const errorIcon = `red-status`

//Default Settings
export const userDefaultSideNavSettings: IMapUiControlSessionSettings = {
  showSubAreas: true,
  showTeachers: false,
  showOtherStaff: false,
  showStudents: false,
  showGuests: false,
  showAreaPanel: true,
  showOldAttackAlerts: false
}

//Form Display Strings

export const firstNameDisplayString = 'First Name'
export const lastNameDisplayString = 'Last Name'
export const emailDisplayString = 'Email Address'
export const phoneDisplayString = 'Phone Number'
export const roleDisplayString = 'Role'

export const firstNamePlaceholder = 'John'
export const lastNamePlaceholder = 'Smith'
export const emailPlaceholder = 'sertAdmin@owlSchools.org'
export const phonePlaceholder = '555-555-5555'