import { Injectable } from '@angular/core'

//3rd party code
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

//Config
import { AppConfigService } from '../app-config/app-config.service'
import { EnvOptions } from '../app-config/env-options'
import { LogConfigKeyValue, OwlLoggingArea, OwlLogLevels } from './logging.model'
import { UserMetaDataService } from './user-meta-data.service'
import { isLocalHost } from '@shared/js-window'

// import { IUtil, ICorrelationIdHelper, IUrlHelper, IDateTimeUtils, FieldType, IRequestHeaders, AIData, AIBase, Envelope, Event, Exception, Metric, PageView, PageViewData, RemoteDependencyData, IEventTelemetry, ITraceTelemetry, IMetricTelemetry, IDependencyTelemetry, IExceptionTelemetry, IAutoExceptionTelemetry, IPageViewTelemetry, IPageViewPerformanceTelemetry, Trace, PageViewPerformance, Data, SeverityLevel, IConfig, ConfigurationManager, ContextTagKeys, DataSanitizer, TelemetryItemCreator, IAppInsights, IPropertiesPlugin, DistributedTracingModes, ITelemetryContext as Common_ITelemetryContext } from "@microsoft/applicationinsights-common";

//App Insights local dev testing - user to test logging from localhost
let overrideAndUseAppInsightsLocally = true
// let overrideAndUseAppInsightsLocally = false

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  useLogging: boolean = false
  appInsights?: ApplicationInsights
  key: string = 'no-app-insights-key'
  env: EnvOptions = EnvOptions.LOCAL

  loggingFlags: LogConfigKeyValue = {
    [OwlLoggingArea.AUTH]: OwlLogLevels.NONE,
    [OwlLoggingArea.EFFECTS]: OwlLogLevels.NONE,
    [OwlLoggingArea.MAP]: OwlLogLevels.NONE,
    [OwlLoggingArea.ROUTING]: OwlLogLevels.NONE
  };

  constructor(
    private appConfig: AppConfigService,
    private userMetaDataService: UserMetaDataService
  ) { }
  init = () => {
    const config = this.appConfig.getConfig()
    if (!config) {
      console.error(`App config not loaded, cannot initialize logging service`)
    }
    this.key = config.APP_INSIGHTS_INSTRUMENTATION_KEY
    this.env = config.env
    this.useLogging = config.USE_LOGGING
    if (this.useLogging) {
      Object.entries(config.LOGGING_FLAGS).forEach((configItem: [string, string]) => {
        const area = configItem[0]
        const level = configItem[1]
        this.loggingFlags[area as OwlLoggingArea] = level as OwlLogLevels
      })
    }

    // TODO Integrate logging flags for easier logging enablement by code areas

    //LOCAL DEV ONLY
    if (isLocalHost()) {
      console.info(`
      Logging service instantiating app insights with
        key: ${this.key}
        env: ${this.env}
        log flags: ${JSON.stringify(this.loggingFlags)}
      `)
    }

    if (!this.appInsights && this.key !== '') {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.key,
          enableAutoRouteTracking: true // option to log all route changes
        }
      })
      // console.log(`In logging service, received user data from auth state, load app insights and pendo`)
      this.appInsights?.loadAppInsights()
    }
  }
  logLocally = (message: string | Object, area: OwlLoggingArea, level: OwlLogLevels): void => {
    const cb = () => console.log(message)
    this._shouldCb(cb, area, level)
  }
  warnLocally = (message: string | Object, area: OwlLoggingArea, level: OwlLogLevels) => {
    const cb = () => console.warn(message)
    this._shouldCb(cb, area, level)
  }

  /** Used for wrapping console methods with conditional logging.  */
  private _shouldCb = (cb: Function, area: OwlLoggingArea, level: OwlLogLevels) => {
    if (this.useLogging && this.canLogBasedOnLogLevelAndArea(area, level)) {
      cb()
    }
  }
  /**
   * For now just log no matter the level or area, TODO Implement once logging tasks for front end area created and billable.
   * Purpose of this function is to receive a log area and log level and check the config if this is allowed to be logged to the console
   * @param area species the requested log area ex: auth
   * @param level specifies the requested log level :ex verbose
   */
  canLogBasedOnLogLevelAndArea = (
    requestedArea: OwlLoggingArea,
    requestedLevel: OwlLogLevels
  ): boolean => {
    // For now just log on local host only, then add support for log areas and log level.
    // return isLocalHost()
    let canLog = false;
    //Iterate over each log level config item and determine if it should be allowed
    Object.entries(this.loggingFlags).forEach((configItem: any) => {
      const configLogArea = configItem[0];
      const configLogLevel = configItem[1];
      if (
        requestedArea === configLogArea &&
        requestedLevel === configLogLevel
      ) {
        canLog = true;
      }
    });
    return canLog;
  }

  logPageView(name?: string, url?: string) {
    if (!this.appInsights) return
    if (this.skipAppInsights()) {
      console.log(`
      Local pageview logging
        name: ${name}
        url: ${url}
      `)
      return
    }
    this.appInsights.trackPageView({
      name: name,
      uri: url
    })
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (!this.appInsights) return
    let stringifiedObject = ''
    try {
      stringifiedObject = JSON.stringify(properties)
    } catch (error) {
      console.warn(`Caught error when stringifying object 
      error: ${error}`)
    }
    if (this.skipAppInsights()) {
      console.log(`
      Local event logging
        name: ${name}
        properties: ${stringifiedObject}
      `)
      return
    }
    this.appInsights.trackEvent(
      { name: `WEB: ${name}` },
      this.userMetaDataService.extendPropsWithUserAnalytics(properties ?? {})
    )
  }

  //TODO Do we want to track page load times as metrics?
  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.skipAppInsights()) {
      console.info(`
      Logging metric, 
      name ${name}
      average ${average}
      properties ${properties}
      `)
      return
    }
    if (!this.appInsights) return
    this.appInsights.trackMetric({ name: name, average: average }, properties)
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.skipAppInsights()) {
      console.info(`Logging exception, message ${exception.message}`)
      console.info(exception)
      return
    }
    if (!this.appInsights) return
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel
    })
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.skipAppInsights()) {
      console.info(`Local trace logging 
      message: ${message}
      properties: ${properties}
      `)
      return
    }
    if (!this.appInsights) return
    this.appInsights.trackTrace({ message: message }, properties)
  }
  skipAppInsights = (): boolean => {
    return this.env === EnvOptions.LOCAL && !overrideAndUseAppInsightsLocally
  }
}
