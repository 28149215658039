import { APP_INITIALIZER } from '@angular/core'
import { NavigationActionTiming, RouterState } from '@ngrx/router-store'

import { environment } from 'src/environments/environment'

import { ExtraOptions } from '@angular/router'
import { ActionReducerMap } from '@ngrx/store'
import { authReducer } from '@reducer/auth.reducer'
import { dashboardReducer } from '@reducer/dashboard-page.reducer'
import { owlRouterReducer } from '@reducer/route.reducer'
import { AppConfigService } from '@service/app-config/app-config.service'
import { IAppState, OwlStateSlices } from '@state/app.state'
import { CustomRouterSerializer } from '@state/router.state'
import { rosterRageReducer } from '@reducer/roster-page.reducer'

export namespace AngularAppConfig {
  //App Config.json related
  export const initConfig = (configService: AppConfigService) => configService.init
  export const appInitConfig = {
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    deps: [AppConfigService],
    multi: true
  }
  export const routingConfig: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
  }
  export const reducers: ActionReducerMap<Partial<IAppState>> = {
    [OwlStateSlices.router]: owlRouterReducer,
    [OwlStateSlices.auth]: authReducer,

    [OwlStateSlices.schoolDashboard]: dashboardReducer,
    [OwlStateSlices.schoolMapConfig]: undefined,
    [OwlStateSlices.schoolValdation]: undefined,

    [OwlStateSlices.roster]: undefined
  }

  // https://v8.ngrx.io/guide/store-devtools/config
  export const devToolsConfig = {
    name: 'Qyler Owl Portal DevTools',
    // Don't display state info on production
    maxAge: environment.production ? 20 : 30,
    logOnly: environment.production,
    autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    trace: environment.production ? false : true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
    traceLimit: environment.production ? 0 : 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
    // features: {
    //   pause: true, // start/pause recording of dispatched actions
    //   lock: true, // lock/unlock dispatching actions and side effects
    //   persist: true, // persist states on page reloading
    //   export: true, // export history of actions in a file
    //   import: 'custom', // import history of actions from a file
    //   jump: true, // jump back and forth (time travelling)
    //   skip: true, // skip (cancel) actions
    //   reorder: true, // drag and drop actions in the history list
    //   dispatch: true, // dispatch custom actions or action creators
    //   test: true // generate tests for the selected actions
    // }
  }

  //Routing
  export const navigationConfig = {
    serializer: CustomRouterSerializer,
    navigationActionTiming: NavigationActionTiming.PostActivation
    // routerState: RouterState.Full
  }
}
