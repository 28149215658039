import { ExtendedAction } from "@action/extended-ngrx-action";
import { PilotUserSignUpDto } from "@model/user/pilot-user-sign-up.model";
import { createAction, props } from "@ngrx/store";

export enum WelcomePageActions {
    showPilotUserSignUpModal = "[Welcome Page Action] Show Pilot User Sign Up Modal",
    submitSignUpPilotUser = "[Welcome Page Action] Submit Sign Up Pilot User",
}

export const showPilotUserSignUpModal = createAction(
    WelcomePageActions.showPilotUserSignUpModal
)
export const submitSignUpPilotUser = createAction(
    WelcomePageActions.submitSignUpPilotUser,
    props<ExtendedAction<PilotUserSignUpDto>>()
)
