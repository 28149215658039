import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ModalComponent } from '@component/shared/modal/modal.component'
import { TransitionModalComponent } from '@component/shared/transition-modal/transition-modal.component'
import { PilotUserSignUpFormComponent } from '@component/user/pilot-user-sign-up-form/pilot-user-sign-up-form/pilot-user-sign-up-form.component'
import { AppConfigService } from '@service/app-config/app-config.service'
import {
  ModalActionResultFunction,
  ModalViewModel,
  defaultModalOptions
} from '@view/matieral/modal.view'
import { PilotUserSignUpModalViewModel } from '@view/user/welcome-user.view'
import { Subscription } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  // TODO Once there are many modal components of various designs set up this pattern
  // private modalRef: ComponentRef<MyModalComponent>;

  // constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef) {}

  // open(title: string, message: string) {
  //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MyModalComponent);
  //   const componentRef = componentFactory.create(this.appRef.injector);

  //   componentRef.instance.title = title;
  //   componentRef.instance.message = message;
  //   componentRef.instance.modalService = this;

  //   this.modalRef = componentRef;

  //   this.appRef.attachView(componentRef.hostView);

  //   const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  //   document.body.appendChild(domElem);
  // }

  /** This is the only modal so far that can be shown above other modals therefore it must have it's own id. */
  activityTimeOutWarningId = 'activity-timeout-warning'
  activityTimeOutSub: Subscription | null = null
  activityTimeOutModalRef: MatDialogRef<any, any> | null = null

  constructor(public dialog: MatDialog, private appConfigService: AppConfigService) { }

  showSchoolStateTransitionModal = (vm: ModalViewModel): void => {
    this.dialog.open(TransitionModalComponent, {
      data: { ...vm },
      ...defaultModalOptions,
      panelClass: 'custom-modal-border-radius'
    })
  }
  showSchoolStateConcludeModal = (
    confirm: ModalActionResultFunction,
    cancel: ModalActionResultFunction
  ): void => {
    const { CONCLUDE_EVENT_MODAL_CONTENT } = this.appConfigService.config ?? {}
    const { TITLE, TITLE_SUBTEXT, CONFIRM_BUTTON_TEXT, CANCEL_BUTTON_TEXT } =
      CONCLUDE_EVENT_MODAL_CONTENT ?? {}
    const vm = new ModalViewModel(
      true,
      'dismiss-suspected-emergency',
      TITLE,
      TITLE_SUBTEXT,
      CONFIRM_BUTTON_TEXT,
      CANCEL_BUTTON_TEXT,
      confirm,
      cancel,
      CONCLUDE_EVENT_MODAL_CONTENT,
      false
    )
    this.dialog.open(TransitionModalComponent, {
      data: { ...vm },
      ...defaultModalOptions,
      panelClass: 'custom-modal-border-radius'
    })
  }

  showTransitionModal = (vm: ModalViewModel): void => {
    const modalOptions = defaultModalOptions
    this.dialog.open(TransitionModalComponent, {
      data: { ...vm },
      ...modalOptions,
      panelClass: 'custom-modal-border-radius'
    })
  }
  showActivityWarningModal = (vm: ModalViewModel): void => {
    const modalOptions = defaultModalOptions
    if (this.activityTimeOutModalRef) {
      return
    }

    this.activityTimeOutModalRef = this.dialog.open(TransitionModalComponent, {
      data: { ...vm },
      ...modalOptions,
      panelClass: 'custom-modal-border-radius',
      id: this.activityTimeOutWarningId
    })
    // Clean up when the activity warning modal is closed
    this.activityTimeOutSub = this.activityTimeOutModalRef
      .afterClosed()
      .subscribe(this.handleActivityTimerModalClose)
  }
  handleActivityTimerModalClose = () => {
    this.activityTimeOutModalRef = null
    this.activityTimeOutSub?.unsubscribe()
  }

  showBasicModal = (vm: ModalViewModel): void => {
    this.dialog.open(ModalComponent, {
      data: { ...vm },
      ...defaultModalOptions
    })
  }


  closeModal = (): void => {
    this.dialog.closeAll()
  }
  showPilotUserSignUpModal = (
    hasSubmitted: boolean
  ): void => {
    const uniqueId = 'pilot-user-sign-up'
    const uniqueConfirmId = 'pilot-user-sign-up-confirm'
    let vm: ModalViewModel
    if (hasSubmitted) {
      vm = new ModalViewModel(
        true,
        '',
        PilotUserSignUpModalViewModel.signUpSuccessTitle,
        `
        ${PilotUserSignUpModalViewModel.signUpSuccessSubtext}
        ${ModalViewModel.lineBreakToken}
        ${PilotUserSignUpModalViewModel.signUpSuccessSubtext2}
        `,
        PilotUserSignUpModalViewModel.closeBtnText
      )
      const modalOptions = defaultModalOptions
      this.dialog.open(TransitionModalComponent, {
        data: { ...vm },
        ...modalOptions,
        id: uniqueConfirmId,
        panelClass: 'custom-modal-border-radius',
      })
    } else {
      vm = new ModalViewModel(
        true,
        '',
        PilotUserSignUpModalViewModel.title,
        PilotUserSignUpModalViewModel.subtext,
        PilotUserSignUpModalViewModel.confirmBtnText,
        PilotUserSignUpModalViewModel.cancelBtnText,
      )
      this.dialog.open(PilotUserSignUpFormComponent, {
        data: { ...vm },
        ...defaultModalOptions,
        id: uniqueId,
        panelClass: 'custom-modal-border-radius'
      })
    }
  }
}
