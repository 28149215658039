import { createFeatureSelector, createSelector } from '@ngrx/store'
import { OwlStateSlices } from '@state/app.state'
import { SchoolMapConfigPageState } from '@state/page/school-map-config-page.state'
import { WelcomePageState } from '@state/page/welcome-page.state'

export const selectWelcomePageState = createFeatureSelector<WelcomePageState>(
    OwlStateSlices.welcome
)

export const selectWelcomePageVm = createSelector(selectWelcomePageState, (state) =>
    state ? state.vm : null
)

export const selectPilotUserSignUpApiState = createSelector(selectWelcomePageState, (state) =>
    state ? state.pilotUserSignUpApi : null
)
export const selectPilotUserSignUpIsLoading = createSelector(
    selectPilotUserSignUpApiState,
    (state) => (state ? state.isLoading : false)
)
export const selectPilotUserSignUpHasError = createSelector(
    selectPilotUserSignUpApiState,
    (state) => (state ? state.hasError : false)
)