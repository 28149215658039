import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseFormModel } from "@model/base-form.model";
import { additionalEmailValidations, additionalPhoneValidations, AdditionalValidations, CustomFormValidators } from "@model/shared/custom-form-validators.model";
import { firstNameDisplayString, lastNameDisplayString } from "@shared/constants";
import { UserTypeVariants } from "./create-update-user.model";
import { Observable } from "rxjs";

export enum PilotUserSignUpProps {
    firstName = 'firstName',
    lastName = 'lastName',
    school = 'school',
    position = 'position',
    city = 'city',
    state = 'state',
    email = 'email',
    phone = 'phone'
}

export interface IPilotUserSignUpFormValue {
    [PilotUserSignUpProps.firstName]: string
    [PilotUserSignUpProps.lastName]: string
    [PilotUserSignUpProps.school]: string
    [PilotUserSignUpProps.position]: string
    [PilotUserSignUpProps.city]: string
    [PilotUserSignUpProps.state]: string
    [PilotUserSignUpProps.email]: string
    [PilotUserSignUpProps.phone]: string
}


export interface PilotUserSignUpDto {
    firstName: string
    lastName: string
    school: string
    position: string
    city: string
    state: string
    phone: string
    email: string
}

export type PilotUserPropsWithValidationType = PilotUserSignUpProps & AdditionalValidations
export type ValidationMessages = {
    [K in PilotUserPropsWithValidationType]: string
}

@Injectable()
export class PilotUserSignUpFormModel extends BaseFormModel<PilotUserSignUpProps> {
    errorMessages: Record<PilotUserPropsWithValidationType, Partial<ValidationMessages>> = {
        [PilotUserSignUpProps.firstName]:
            CustomFormValidators.getTextInputAdditionalValidations(
                firstNameDisplayString
            )
        ,
        [PilotUserSignUpProps.lastName]: CustomFormValidators.getTextInputAdditionalValidations(
            lastNameDisplayString
        ),
        [PilotUserSignUpProps.email]: additionalEmailValidations,
        [PilotUserSignUpProps.phone]: additionalPhoneValidations,
        [PilotUserSignUpProps.school]: CustomFormValidators.getTextInputAdditionalValidations
            ('School'),
        [PilotUserSignUpProps.position]: CustomFormValidators.getTextInputAdditionalValidations
            ('Position'),
        [PilotUserSignUpProps.city]: CustomFormValidators.getTextInputAdditionalValidations
            ('City'),
        [PilotUserSignUpProps.state]: CustomFormValidators.getTextInputAdditionalValidations
            ('State'),
    }
    constructor(private fb: FormBuilder) {
        super()

    }
    getChangeObs(): Observable<IPilotUserSignUpFormValue> {
        return this._form.valueChanges
    }
    private _createFormGroup = (
        dto?: PilotUserSignUpDto): Partial<Record<PilotUserSignUpProps, FormControl>> => {
        const minLength = 2
        const sharedValidators = CustomFormValidators.getNameValidators(this.fb, minLength)
        return {

            [PilotUserSignUpProps.firstName]:
                this.fb.control(dto?.[PilotUserSignUpProps.firstName] ?? '', sharedValidators),
            [PilotUserSignUpProps.lastName]:
                this.fb.control(dto?.[PilotUserSignUpProps.lastName] ?? '', sharedValidators),


            //If we need other validation other than the usual name validations, we can add them here.
            [PilotUserSignUpProps.school]:
                this.fb.control(dto?.[PilotUserSignUpProps.school] ?? '',
                    sharedValidators
                ),
            [PilotUserSignUpProps.position]:
                this.fb.control(dto?.[PilotUserSignUpProps.position] ?? '',
                    sharedValidators
                ),
            [PilotUserSignUpProps.city]:
                this.fb.control(dto?.[PilotUserSignUpProps.city] ?? '',
                    sharedValidators
                ),

            // This is from a drop down. We can add a custom validator here if needed.
            [PilotUserSignUpProps.state]:
                this.fb.control(dto?.[PilotUserSignUpProps.state] ?? null, Validators.required
                ),

            [PilotUserSignUpProps.email]:
                this.fb.control(dto?.[PilotUserSignUpProps.email], [Validators.required, CustomFormValidators.trimmedEmailValidator(this.fb)]),

            [PilotUserSignUpProps.phone]:
                this.fb.control(dto?.[PilotUserSignUpProps.phone],
                    CustomFormValidators.getPhoneValidaitonsByType(UserTypeVariants.web)
                )
        }
    }
    private _createForm = (
        dto?: PilotUserSignUpDto
    ) => {
        return this.fb.group(this._createFormGroup(dto))
    }
    _form: FormGroup = this._createForm()

}